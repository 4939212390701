import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AiFillHome, AiOutlineMinusSquare } from "react-icons/ai";
import { MdOutlineAddBox, MdOutlineMinusBox, MdOutlineWarehouse, MdOutlineReadMore } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import { AuthContext } from "../AuthContext";
import AddBolotaModal from "../Mobile/AddBolotaModal";
import EditBolotaModal from "../Mobile/EditBolotaModal";
import './ProducerLayout.css';
//import "./Produtores.css"
//import "../Admin/Produtores.css"
import ProposalModal from '../Admin/ProposalModal'; // Certifique-se de que o caminho está correto
import AprovalModal from '../Admin/AprovalModal'; // Certifique-se de que o caminho está correto
import DataModal from './DataModal';
import DataModalEdit from './DataModal';
import SuccessModal from "../Desktop/SuccessModal";  
import SuccessModal2 from "../Desktop/SuccessModal"; 
import AprovalModalData from '../Admin/AprovalModal';  

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);
function formatAcornType(acornType) {
  return acornType
    .replace(/_/g, " ")  // Substitui '_' por ' '
    .toLowerCase()       // Converte tudo para minúsculas
    .split(" ")          // Divide as palavras
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitaliza a primeira letra
    .join(" ");          // Junta as palavras de volta
}

function ProducerLayout() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [produtorData, setProdutorData] = useState({});
  const [pricesData, setPricesData] = useState({});
  
  const [producerId, setProducerId] = useState(null);
  const [data_delivery, setdata_delivery] = useState(null);
  
  const [, dashboard, , type] = history.location.pathname.split("/");
  const { token } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [idProducer, setIdProducer] = useState(null);



const fetchProducerData = async (id) => {
  try {
    // Buscar os dados do produtor
    const response = await fetch(`${URL}/webland-api/producer/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const producerData = await response.json();

    // Buscar os preços associados
    const priceResponse = await fetch(`${URL}/webland-api/price/prices/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const pricesData = await priceResponse.json();

    // Mapear os dados do produtor e associar os preços, status e type
    const produtorWithType = producerData?.map((produtor) => {
      const updatedStockBolota = produtor?.stockBolota?.map((item) => {
        // Comparar `acornType` e `name_product` ignorando maiúsculas/minúsculas
        const matchedPrice = pricesData.find(
          (price) =>
            price.name_product.toLowerCase() === item.acornType.toLowerCase()
        );
        return {
          ...item,
          price: matchedPrice ? matchedPrice.price : "N/A", // Preço correspondente
          status: matchedPrice ? matchedPrice.status : "N/A", // Status correspondente
          type: matchedPrice ? matchedPrice.type : "N/A", // Tipo correspondente
          id: matchedPrice ? matchedPrice._id : "N/A", // ID do preço
        };
      });

      return {
        ...produtor,
        type: "produtor",
        stockBolota: updatedStockBolota,
      };
    });

    setProdutorData(produtorWithType[0]);

    // Agora, busque as datas de entrega para cada item
    const deliveryDates = await fetchDeliveryDates(produtorWithType[0].stockBolota);

    // Associa as datas de entrega ao `stockBolota`
    const updatedStockBolotaWithDelivery = produtorWithType[0].stockBolota.map((item) => {
      // Encontre a data de entrega correspondente pelo `delivery_date` (que é o ID)
      const matchedDelivery = deliveryDates[item.delivery_date];

      // Retorne o item com os dados de entrega adicionados
      return {
        ...item,
        deliveryData: matchedDelivery || {},  // Adiciona os dados de entrega ou objeto vazio se não for encontrado
      };
    });

    // Atualiza o estado com o stockBolota atualizado
    setProdutorData((prevProdutorData) => ({
      ...prevProdutorData,
      stockBolota: updatedStockBolotaWithDelivery,
    }));

    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching producer data:", error);
  }
};

  // Função para buscar as datas de entrega
const fetchDeliveryDates = async (stockBolota) => {
  const fetchPromises = stockBolota
    .filter((item) => item.delivery_date) // Filtra os itens com delivery_date
    .map(async (item) => {
      try {
        // Faz a requisição para buscar a data de entrega pelo ID
        const response = await fetch(`${URL}/webland-api/delivery_date/getDeliveryData/${item.delivery_date}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("item", item.delivery_date);

        // Verifica se a resposta é válida
        if (!response.ok) {
          throw new Error(`Erro ao buscar data de entrega para ID ${item.delivery_date}: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("data", item.delivery_date, data);

        // Retorna um objeto com o ID e os dados da entrega
        return { id: item.delivery_date, data };
      } catch (error) {
        console.error(`Erro ao buscar data de entrega: ${error.message}`);
        return { id: item.delivery_date, delivery_date: null }; // Retorna null em caso de erro
      }
    });

  // Aguarda que todas as promessas de fetch sejam resolvidas
  const deliveryDataArray = await Promise.all(fetchPromises);

  // Retorna um objeto com os dados de entrega organizados por delivery_date
  const deliveryDataMap = deliveryDataArray.reduce((acc, deliveryData) => {
    acc[deliveryData.id] = deliveryData.data;
    return acc;
  }, {});

  return deliveryDataMap;
};
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("id");
    const emailFromURL = urlParams.get("email");

    const fetchIdByEmail = async (email) => {
      try {
        const response = await fetch(`${URL}/webland-api/producer/idByEmail/${email}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setIdProducer(data.id);
        } else {
          console.error("Falha ao buscar os dados do usuário");
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
      }
    };

    if (emailFromURL) {
      fetchIdByEmail(emailFromURL);
    }
    if (idFromURL) {
      setProducerId(idFromURL);
      fetchProducerData(idFromURL);
    }
  }, []);

  useEffect(() => {
   
    if (idProducer) {
      fetchProducerData(idProducer);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("id");

    if (idFromURL) {
      setProducerId(idFromURL);
      fetchProducerData(idFromURL);
    }
    console.log("valor stock bolota",stockBolota);
  }, [idProducer,]);

  const handleModalClose = () => {
    setShowModal(false);
    setIsEditing(false);
    fetchProducerData(producerId || idProducer);
  };

  const { stockBolota, warehouseHistory } = produtorData;


  
  const chartStockData = {
    labels: stockBolota?.map((item) => formatAcornType(item.acornType)), // Aplica a função
    datasets: [
      {
        data: stockBolota?.map((item) => item.quantity),
        backgroundColor: [
          "#ffa500",  // Carvalho Português
          "#5d643f",  // Carvalho Negral
          "#8e9475",  // Carvalho Alvarinho
          "#f4a460",  // Azinheira
          "#E6E6E6",  // Sobreiro
        ],
      },
    ],
  };

  return isLoading ? (
    <Spinner />
  ) : (
   <div className="producer-metrics-container">
     
      <h2 style={{ textTransform: "uppercase" }}>{produtorData.name}</h2>
      <div className="row">
        <div className="col-md-10">
          <WarehouseStock
            stockBolota={stockBolota}
            showModal={showModal}
            setShowModal={setShowModal}
            setIsEditing={setIsEditing}
            editedProduct={editedProduct}
            setEditedProduct={setEditedProduct}
            isEditing={isEditing}
            onModalClose={handleModalClose} 
          />
        </div>
        {/*
        <div className="col-md-3">
        <Doughnut data={chartStockData} />
        </div>
       
        <div className="col-md-5">
          <WarehouseHistory history={warehouseHistory} />
        </div>*/}
      </div>
    </div>
  );
}

const Breadcrumb = ({ producerId, dashboard }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="breadcrumb-wrapper">
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard')}>
        {dashboard ? dashboard.toUpperCase() : 'DASHBOARD'}
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard/produtores')}>
        Produtores
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item">
        {producerId}
      </span>
    </div>
  );
};

const ProducerInfo = ({ produtorData }) => {
  const { nif, email, phoneNumber, address, region, lat, long, createdAt } = produtorData;

  return (
    <div className="card producer-info-card">
      <div className="card-body">
        <h5 className="card-title"> Informação do Produtor</h5>
        <p><strong>NIF:</strong> {nif}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Telefone:</strong> {phoneNumber}</p>
        <p><strong>Morada:</strong> {address}</p>
        <p><strong>Zona:</strong> {region}</p>
        <p><strong>Latitude:</strong> {lat}</p>
        <p><strong>Longitude:</strong> {long}</p>
        <p><strong>Data de criação:</strong> {new Date(createdAt).toLocaleDateString()}</p>
      </div>
    </div>
  );
};

const WarehouseStock = ({
  stockBolota,
  showModal,
  setShowModal,
  setIsEditing,
  setEditedProduct,
  isEditing,
  editedProduct,
  onModalClose, 
}) => {
  const chartStockData = {
    labels: stockBolota?.map((item) => item.acornType),
    datasets: [
      {
        data: stockBolota?.map((item) => item.quantity),
        backgroundColor: ["#ffa500", "#5d643f", "#8e9475"],
      },
    ],
  };

  const handleEditClick2 = (product) => {
    setIsEditing(true);
    setEditedProduct(product);
  };

  const [ShowProposalModal, setShowProposalModal] = useState(false);
  const [newPrice, setNewPrice] = useState(''); // Estado para armazenar o novo preço
  const [selectedItem, setSelectedItem] = useState(null); // Estado para armazenar o item selecionado
  const [showModalData, setShowModalData] = useState(false); 
  const [newdeliveryDate, setNewDeliveryDate] = useState(""); 
  const [showSuccessModal, setShowSuccessModal] = useState(false); 

  const handleOpenProposalModal = (item) => {
    console.log("item",item)
    setSelectedItem(item);
    setShowProposalModal(true);
    
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);  // Fecha o SuccessModal
    window.location.reload();  
  };

  const handleConfirmProposalModal = async () => {
    if (!selectedItem || !newPrice) return; // Verifique se existe um item selecionado e um preço
    console.log("estou cá");
    try {
      const priceId = selectedItem.id; // Obtenha o ID do preço
      const response = await fetch(`${URL}/webland-api/price/update/${priceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`        },
        body: JSON.stringify({ price: newPrice , status: "pendente", type:"produtor"}), // Envie o novo preço
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar o preço');
      }

      const updatedPrice = await response.json();
      console.log("Preço atualizado com sucesso!", updatedPrice);
      // Atualize os dados conforme necessário ou recarregue a página

      // Opcional: Recarregar a página ou atualizar os dados
     

    } catch (error) {
      console.error("Erro:", error.message);
    } finally {
      setShowProposalModal(false); // Fecha o modal
      setShowSuccessModal(true); 
      setNewPrice(''); // Limpa o campo de entrada
    }
  };

  const handleCancelProposalModal = () => {
    setShowProposalModal(false);
    setNewPrice(''); // Limpa o campo de entrada ao cancelar
  };

  const [showAprovalModal, setshowAprovalModal] = useState(false);
  const [showAprovalModalData, setshowAprovalModalData] = useState(false);
  const [showDeclineModalData, setshowDeclineModalData] = useState(false);

  const handleOpenModalAprovalModal = () => {
    setshowAprovalModal(true);
  };

  const handleConfirmAprovalModal = async (stockBolota) => {
    console.log("stockBolota2", stockBolota);
    try {
      // Substitua `priceId` pelo ID do preço que você deseja atualizar
      const priceId = stockBolota.id; // Altere isso para a maneira como você está armazenando o ID do preço
      const response = await fetch(`${URL}/webland-api/price/concluido/${priceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao atualizar o preço');
      }
  
      const updatedPrice = await response.json();
      console.log("Preço aceito!", updatedPrice);
      // Aqui você pode adicionar qualquer lógica adicional que deseje executar após a atualização
      //window.location.reload()
     

    } catch (error) {
      console.error("Erro:", error.message);
    } finally {
     setshowAprovalModal(false);
     window.location.reload();
     setShowModalData(true);
    }
  };
  

  const handleConfirmAprovalModalData = async (item) => {
    console.log("dentro modal", item);
    try {
      // Faz a requisição PUT para atualizar o status da entrega
      const response = await fetch(`${URL}/webland-api/delivery_date/updateconcluido/${item}`, { // Supondo que item tenha a propriedade deliveryId
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`     // Adicione seu token de autorização se necessário
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result); // Log do resultado para debug

      // Aqui você pode atualizar o estado ou fazer o que for necessário após a atualização
      setshowAprovalModalData(false); // Fecha o modal após a confirmação
        // Recarregar a página para refletir as mudanças
    window.location.reload();
    } catch (error) {
      console.error('Erro ao atualizar os dados da entrega:', error);
      // Trate o erro conforme necessário, talvez mostrando uma mensagem ao usuário
    }
  };
  
  const handleConfirmDeclineModalData = async (item, newdate) => {
    console.log("dentro modal declínio", item);
  try {
    // Define os valores que deseja atualizar
    const updatedData = {
      status: "pendente",  // Exemplo de status ao rejeitar
      type: "produtor",     // Tipo pode ser "declined" ou conforme a lógica da sua aplicação
      delivery_date: newdate // Mantém a data de entrega existente
    };

    // Faz a requisição PUT para atualizar os dados da entrega
    const response = await fetch(`${URL}/webland-api/delivery_date/update/${item}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}` // Adicione seu token de autorização se necessário
      },
      body: JSON.stringify(updatedData)  // Envia os dados no corpo da requisição
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    console.log("Resultado da rejeição:", result); // Log do resultado para debug

    // Aqui você pode atualizar o estado ou fazer o que for necessário após a atualização
    setshowDeclineModalData(false); // Fecha o modal após a confirmação
    window.location.reload(); // Recarregar a página para refletir as mudanças
  } catch (error) {
    console.error('Erro ao atualizar os dados da entrega:', error);
    // Trate o erro conforme necessário, talvez mostrando uma mensagem ao usuário
  }
};
  


  const handleCancelAprovalModal = () => {
    
    setshowAprovalModal(false);
  };
  
  const handleCancelAprovalModalData = () => {
   
    setshowAprovalModalData(false);
  };
  const handleOpenAprovalModalData = () => {

    setshowAprovalModalData(true);
  };

  const handleCloseDeclineModalData = () => {
   
    setshowDeclineModalData(false);
  };
  const handleOpenDeclineModalData = () => {

    setshowDeclineModalData(true);
  };

  const handleOpenModalData = () => {
    setShowModalData(true);
  };

  const handleCloseModalData = () => {
    setShowModalData(false);
  };



  const handleConfirmData = async (delivery_date) => {
    console.log("id de atualização",delivery_date);
    try {
      // Fazer a requisição PUT para a rota de atualização
      const response = await fetch(`${URL}/webland-api/delivery_date/update/${delivery_date}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'pendente',     // Atualiza o status para 'concluído'
          type: 'produtor',         // Mantém o tipo (type) atual do item
          delivery_date: newdeliveryDate // Atualiza a data de entrega com o valor selecionado
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Entrega atualizada com sucesso:', data);
        // Aqui você pode atualizar o estado ou a interface com as novas informações
      } else {
        console.error('Erro ao atualizar a entrega:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
// Recarregar a página para refletir as mudanças
window.location.reload();
    setShowModalData(false); // Fechar o modal após confirmação
  };




  const StockBolotaList = ({ stockBolota }) => {
    console.log("stockBolotafinal",stockBolota);
    return (
<div className="stock-bolota-container" style={{ maxHeight: "100%" }}>
  <div className="stock-bolota-header" style={{ display: "flex", justifyContent: "space-between" }}>
    <div className="stock-bolota-header-cell">Espécie</div>
    <div className="stock-bolota-header-cell">Quantidade</div>
    <div className="stock-bolota-header-cell">Data de colheita</div>
    <div className="stock-bolota-header-cell">Preço</div>
    <div className="stock-bolota-header-cell">Estado</div>
    <div className="stock-bolota-header-cell">Data de Entrega</div>
    <div className="stock-bolota-header-cell">Ações Preço</div>
  </div>
  {stockBolota && stockBolota.length > 0 ? (
  stockBolota?.map((item, index) => (
    <div key={index} className="stock-bolota-item" style={{ display: "flex", justifyContent: "space-between", maxHeight: "100%" }}>
      <div className="stock-bolota-cell">
        {formatAcornType(item.acornType)}
      </div>
      <div className="stock-bolota-cell">
        {item.quantity}kg
      </div>
      <div className="stock-bolota-cell">
        {new Date(item.harvest_date).toLocaleDateString('pt-PT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })}
      </div>
      <div className="stock-bolota-cell">
        {item.price}€
      </div>
      <div className="stock-bolota-cell">
        {item.status === "pendente" && item.type === "produtor"
          ? "Pendente da decisão Administrador"
          : item.status === "concluido"
          ? `Preço aprovado pelo administrador: ${item.price}€`
          : "Pendente da decisão Administrador"}
      </div>
      <div className="stock-bolota-header-cell">
  {item.status === "concluido" && item?.deliveryData?.status === "concluido" ? (
    // Mostrar a data de entrega quando o status for concluído
    item?.deliveryData?.delivery_date
  ) : item?.deliveryData?.status === "pendente" && item?.deliveryData?.type === "admin" && item.status === "concluido" ? (
    // Exibir opções para aceitar ou recusar quando o status for pendente e o tipo for admin
    <>
      {/*<button onClick={() => aceitarEntrega(item.id)}>Aceitar</button>
      <button onClick={() => recusarEntrega(item.id)}>Recusar</button>*/}
      {item?.deliveryData?.delivery_date}
      <FaCheck
              style={{ width: "40%", height: "40%", cursor: "pointer" }}
              onClick={handleOpenAprovalModalData}
            />
  {/* Modal */}
  {showAprovalModalData && (
        <AprovalModalData 
          message={`Aceitar a data ${item?.deliveryData?.delivery_date} proposta pelo Administrador ?`} 
          onConfirm={() => handleConfirmAprovalModalData(item?.deliveryData?._id)} // Passando a função corretamente
          onCancel={handleCancelAprovalModal} 
        />
      )}
    
              <RxCross2
              style={{ width: "40%", height: "40%", color: "#dc3300", cursor: "pointer" }}
              onClick={() => handleOpenDeclineModalData(item)}
            />
             {showDeclineModalData && (
        <DataModalEdit 
        message="Insira nova data de Entrega:"
        delivery_date={
          <input
            type="date"
            value={newdeliveryDate}
            onChange={(e) => setNewDeliveryDate(e.target.value)} // Atualiza o estado com a data
          />
        }
          onConfirm={() => handleConfirmDeclineModalData(item?.deliveryData?._id,newdeliveryDate)} // Passando a função corretamente
          onCancel={handleCloseDeclineModalData} 
        />
      )}
    </>
  ) : item?.deliveryData?.status === "pendente" && item?.deliveryData?.type === "produtor"  && item.status==="concluido" ? (
    // Mensagem de "A espera validação do admin" quando o status for pendente e o tipo for produtor
    <span>A espera validação do administrador</span>
  ) : item.status === "concluido" &&  item?.deliveryData?.status != "concluido"  ? (
    <>
   
    <button onClick={handleOpenModalData}>Inserir</button>

    {showModalData && (
      <DataModal
        message="Por favor, insira a data de entrega:"
        delivery_date={
          <input
            type="date"
            value={newdeliveryDate}
            onChange={(e) => setNewDeliveryDate(e.target.value)} // Atualiza o estado com a data
          />
        }
        onConfirm={()=>handleConfirmData(item.delivery_date)}
        onCancel={handleCloseModalData}
        />
      )}
    </>
  ) : (
    // Caso não atenda a nenhuma das condições, exibe uma mensagem ou mantém vazio
    <span>--</span>
  )}
</div>

      <div className="stock-bolota-cell">
        {item.status === "pendente" && item.type === "admin" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCheck
              style={{ width: "50%", height: "15%", cursor: "pointer" }}
              onClick={handleOpenModalAprovalModal}
            />
            {showAprovalModal && (
              <AprovalModal
                message={`Aceitar o preço de ${item.price}€/Kg?`}
                onConfirm={() => handleConfirmAprovalModal(item)} 
                onCancel={handleCancelAprovalModal}
              />
            )}
            <RxCross2
              style={{ width: "50%", height: "30%", marginLeft: "15%", color: "#dc3300", cursor: "pointer" }}
              onClick={() => handleOpenProposalModal(item)}
            />
            </div>
          ) : (
          null
          )}
        </div>
      </div>
    ))
  ) : (
    <div className="stock-bolota-empty">
      <p>Sem stock de bolota disponível. Por favor insira Stock.</p>
    </div>
  )}
  </div>
   
)}
  

  return (
    <div className="card warehouse-stock-card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-10">
        <h5 className="card-title">Stock Armazém</h5></div>
        <div className="col-md-2"   style={{ marginTop: "2%"
  }}>
     <button
    className="col-10 add-btn"
    style={{
        background: "var(--primary-color)",
       
        padding: "10px 10px 10px 10px", // Topo e fundo: 15px, Lado esquerdo e direito: 30px
        // Aumente o padding para dar mais espaço
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      
    }}
    onClick={() => setShowModal(true)}
>
    Adicionar Stock
</button>
       </div>
        </div>
        <StockBolotaList stockBolota={stockBolota} />
       {/* <Doughnut data={chartStockData} />
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <button
            className="col-12 add-btn"
            style={{ background: "var(--focus-color)", marginTop: "5%" }}
            onClick={() => setShowModal(true)}
          >
           +
          </button>
        </div>*/}

        <AddBolotaModal isOpen={showModal} onClose={onModalClose} />

        {isEditing && (
          <EditBolotaModal
            isOpen={isEditing}
            onClose={onModalClose}
            isEditMode={true}
            initialProduct={editedProduct}
          />
        )}
          {ShowProposalModal && (
        <ProposalModal 
          message="Insira o novo preço:"
          price={
            <input 
              type="text" 
              value={newPrice} 
              onChange={(e) => setNewPrice(e.target.value)} 
              placeholder="Novo preço" 
            />
          }
          onConfirm={handleConfirmProposalModal} 
          onCancel={handleCancelProposalModal} 
        />
      )}
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={handleCloseSuccessModal}         // Fecha o SuccessModal
        message="A proposta foi enviada com sucesso!"
        title="Sucesso"
      />
      </div>
    </div>
  );
};

const WarehouseHistory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Sort the history in descending order by date
  const sortedHistory = [...history].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  return (
    <div className="card warehouse-stock-card">
      <div className="card-body">
        <div className="warehouse-history-container">
          <h5 className="card-title">Histórico Armazém</h5>
          <div className="warehouse-history-header">
            <div className="warehouse-history-cell" style={{ textAlign: "left" }}>Data</div>
            <div className="warehouse-history-cell" style={{ textAlign: "left" }}>Espécie</div>
            <div className="warehouse-history-cell" style={{ textAlign: "left" }}>Quantidade</div>
            <div className="warehouse-history-cell" style={{ textAlign: "left" }}>Mov.</div>
          </div>
          <div className="warehouse-history-body">
            {sortedHistory.map((item, index) => (
              <div key={index} className="warehouse-history-item" style={{ textAlign: "left" }}>
                <span className="warehouse-history-cell" style={{ textAlign: "left" }}>
                  {new Date(item.timestamp).toLocaleDateString()}
                </span>
                <span className="warehouse-history-cell" style={{ textAlign: "left" }}> {formatAcornType(item.acornType)}</span>
                <span className="warehouse-history-cell" style={{ textAlign: "left" }}>{item.quantity}Kg</span>
                <span className="warehouse-history-cell" style={{ textAlign: "left" }}>
                  {item.transactionType === "IN" ? (
                    <MdOutlineAddBox color="#43806c" />
                  ) : (
                    <AiOutlineMinusSquare color="#ad1126" />
                  )}
                </span>
              </div>
              
            ))}
            
          </div>
          
        </div>
        
      </div>
    </div>
  );
};

export default ProducerLayout;
